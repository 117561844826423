import React from 'react';
import './landing.css'

const Landing = () => {
  return (
    <div>
        <div className='landing__main__container'>
            <div className='landing__main__container__div'>
                <h1>Get Back on the Road to Recovery with Our Rehabilitation Clinic</h1>
                <p>Our clinic in Tampa Bay Area specializes in personalized rehabilitation for car accident recovery. With state-of-the-art facilities and a compassionate approach, we deliver proven results. Contact us today to schedule your consultation.</p>
                <a className='btn__cta__border__mantis' href="tel: (813) 353 1380">Call Now</a>
            </div>
        </div>
    </div>
  )
}

export default Landing