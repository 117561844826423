import React from 'react';
import './home.css';
import Landing from '../components/Landing';
import Testimonial from '../components/Testimonial';
import { Link } from 'react-router-dom';
import HandleNavegation from '../components/HandleNavegation';

const Home = () => {
  return (
    <div>
      <Landing />
      <div className='home__container__1'> 
        <h2>Revitalize Your Recovery at HC Rehab Center: Tailored Care for Car Accident Survivors</h2>
        <p>At HC Rehab Center in Tampa Bay Florida, we recognize that every car accident survivor faces a unique path to recovery. That's why we prioritize personalized rehabilitation care plans tailored to your individual needs and medical history. Our expert team of therapists, chiropractors, and massage specialists collaborate to create comprehensive rehabilitation programs at our Tampa Bay Area rehabilitation center. Aimed at reducing pain, restoring mobility, and improving overall well-being, our car accident rehabilitation center is equipped to handle whiplash, back injuries, and trauma-induced stress. Trust HC Rehab Center in Tampa, Florida, USA, to guide you towards a smoother, more revitalized recovery journey.</p>
        {/* <a className='btn__cta__border bg__darker' href="/services">Learn More</a> */}
      </div>
      <div className='home__container__2'>
        <div>
          <h2>Unmatched Support for Slip and Fall Victims: Rehabilitation Strategies at HC Rehab Center in Tampa Bay</h2>
          <p>Slip and fall accidents can lead to a range of debilitating injuries, affecting your ability to navigate daily life with ease. At HC Rehab Center, we’re dedicated to providing unparalleled rehabilitation support and rehabilitation services tailored specifically to slip and fall victims in Tampa Bay Area. Our skilled practitioners at our Tampa Bay Area rehabilitation center employ a combination of evidence-based therapies, including physical therapy, chiropractic adjustments, and specialized massages. We address your unique challenges to facilitate a faster, more effective rehabilitation recovery. With HC Rehab Center, your trusted car accident rehabilitation recovery and rehab clinic in Tampa Florida, you can regain your footing and reclaim your independence with confidence.</p>
          {/* <a className='btn__cta__border bg__darker' href="/services">Learn More</a> */}
        </div>
        <div>
          <h2>Holistic Healing Solutions: Transformative Care at HC Rehab Center</h2>
          <p>At HC Rehab Center in Tampa Bay Area, we understand that true healing extends beyond physical recovery—it encompasses mental, emotional, and spiritual well-being as well. That’s why we take a holistic approach to rehabilitation at our Tampa Bay rehabilitation clinic, addressing not only the symptoms of your injury but also the underlying factors contributing to your discomfort and distress. Our integrated treatment plans combine traditional therapies with innovative techniques, including physical therapy, chiropractic adjustments, and specialized massages, to promote comprehensive healing from within. Whether you're seeking relief from chronic pain, aiming to improve your range of motion, or striving for a greater sense of balance and vitality, HC Rehab Center in Tampa Florida, your trusted car accident rehabilitation center, is here to support you every step of the way.</p>
          {/* <a className='btn__cta__border bg__darker' href="/services">Learn More</a> */}
        </div>
      </div>
        <div className='home__container__3'>
          <h2>"Explore Our Rehabilitation Programs at HC Rehab Center in Tampa Bay area and Begin Your Journey to Health and Healing. Discover tailored solutions for car accident recovery, physical therapy, and holistic rehabilitation in Tampa, FL."</h2>
          <Link className='btn__cta__border bg__darker' to="/services" onClick={HandleNavegation()}>Learn More</Link> 
        </div>
        <Testimonial />
    </div>
  )
}

export default Home;